<template>
  <div class="app_con">
    <app-list
      ref="applist"
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :btnFlag="{ addShow: true, editShow: false, deleteShow: false }"
      :rules="rules"
      :editTitle="'接口参数'"
      :isInline="false"
      :labelWidth="'120px'"
      @listDone="getAppListData"
    >
      <div slot="toolSub" selecTionArr="">
        <el-button
          size="small"
          type="success"
          icon="el-icon-menu"
          @click="handleSwitch('on')"
          >批量开启</el-button
        >
        <el-button
          size="small"
          type="danger"
          icon="el-icon-menu"
          @click="handleSwitch('off')"
          >批量关闭</el-button
        >

        <el-button
          size="small"
          type="warning"
          icon="el-icon-time"
          @click="setSwitchTime"
          >批量定时</el-button
        >

        <el-button
          size="small"
          type="primary"
          icon="el-icon-user"
          @click="batchSetUser"
          >批量配置用户</el-button
        >
      </div>
    </app-list>

    <el-dialog
      :title="isBatch ? '批量配置代售点' : '配置单个代售点'"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
    >
      <div class="uesrCon">
        <el-table
          :data="userList"
          height="290"
          ref="multipleTable"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>

          <el-table-column prop="name" label="姓名" width="180">
          </el-table-column>
          <el-table-column prop="departmentName" label="部门">
          </el-table-column>
          <el-table-column prop="payAccountName" label="支付宝">
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="定时开关"
      :visible.sync="openDialogVisible"
      width="580px"
      :close-on-click-modal="false"
    >
      <div class="con">
        <div class="item">
          <span>开启-白班：</span
          ><el-time-picker
            v-model="openTime"
            placeholder="开启时间"
            value-format="HH:mm"
            format="HH:mm"
          >
          </el-time-picker>
        </div>
        <div class="item">
          <span>关闭-白班：</span
          ><el-time-picker
            v-model="closeTime"
            placeholder="关闭时间"
            value-format="HH:mm"
            format="HH:mm"
          >
          </el-time-picker>
        </div>
        <div class="item">
          <span>开启-晚班：</span
          ><el-time-picker
            v-model="twoOpenTime"
            placeholder="开启时间"
            value-format="HH:mm"
            format="HH:mm"
          >
          </el-time-picker>
        </div>
        <div class="item">
          <span>关闭-晚班：</span
          ><el-time-picker
            v-model="twoCloseTime"
            placeholder="关闭时间"
            value-format="HH:mm"
            format="HH:mm"
          >
          </el-time-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer centerDiv">
        <el-button size="small" @click="openDialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="handleTime"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    let self = this;
    return {
      openTime: "06:05",
      closeTime: "07:48",
      twoOpenTime: "08:05",
      twoCloseTime: "23:45",
      openDialogVisible: false,
      remote: api.common,
      dialogVisible: false,
      apiName: "/ctripApiParam",
      searchVal: "",
      jobList: [],
      appListData: [],
      dataLength: 0,
      ids: [],
      isBatch: false,
      form: {
        userIdList: [],
        id: "",
        apiName: "/ctripApiParam/update",
        agentCode: "",
      },
      userList: [],
      typeList: [
        {
          label: "启用",
          value: 0,
        },
        {
          label: "禁用",
          value: 1,
        },
      ],
      bntLoading: false,

      statusMap: {},
      searchControl: {
        firstSoltSpan: [3, 3, 3, 3],
        secondSoltSpan: [9, 9, 9, 9],
        thirdSoltSpan: [10, 10, 10, 10],
      },
      searchQuery: { randNum: 0 },
      rules: {
        //传递给APPlist的表单验证
        agentCode: [
          {
            required: true,
            message: "请输入agentCode",
            trigger: ["blur", "change"],
          },
        ],
        agentName: [
          {
            required: true,
            message: "请输入agentName",
            trigger: ["blur", "change"],
          },
        ],
        agentKey: [
          {
            required: true,
            message: "请输入agentKey",
            trigger: ["blur", "change"],
          },
        ],
        payAccountName: [
          {
            required: true,
            message: "请输入支付账号",
            trigger: ["blur", "change"],
          },
        ],
        accountId: [
          {
            required: true,
            message: "请输入accountId",
            trigger: ["blur", "change"],
          },
        ],
        agreementNo: [
          {
            required: true,
            message: "请输入agreementNo",
            trigger: ["blur", "change"],
          },
        ],
        loginName: [
          {
            required: true,
            message: "请输入登录账号",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "agentCode",
          prop: "agentCode",
          align: "center",
          width: "120",
        },
        {
          label: "agentName",
          prop: "agentName",
          align: "center",
        },

        {
          label: "agentKey",
          prop: "agentKey",
          align: "center",
        },
        {
          label: "支付账号",
          prop: "payAccountName",
          align: "center",
        },
        {
          label: "accountId",
          prop: "accountId",
          align: "center",
        },
        {
          label: "agreementNo",
          prop: "agreementNo",
          align: "center",
        },
        {
          label: "当前状态",
          prop: "id",
          align: "center",
          width: "100px",
          formatter: function (row) {
            self.getStatus(row.id);

            return self.statusMap[row.id];
          },
        },
        {
          label: "定时开启",
          prop: "openTime",
          align: "center",
          width: "120px",
          formatter: function (row) {
            return row.openTime + "\n||\n" + row.twoOpenTime;
          },
        },
        {
          label: "定时关闭",
          prop: "closeTime",
          align: "center",
          width: "120px",
          formatter: function (row) {
            return row.closeTime + "\n||\n" + row.twoCloseTime;
          },
        },
        {
          label: "登录账号",
          prop: "loginName",
          align: "center",
          width: "100px",
        },
        {
          label: "操作",
          align: "center",
          width: "230px",
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "用户",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-user",
                type: "success",
                handler: function () {
                  self.handleUsers(row.id);
                },
              },
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "edit",
                handler: function () {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "delete",
                handler: function () {},
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "agentCode",
          prop: "agentCode",
          type: "input",
        },
        {
          label: "agentName",
          prop: "agentName",
          type: "input",
        },

        {
          label: "agentKey",
          prop: "agentKey",
          type: "input",
        },
        {
          label: "支付账号",
          prop: "payAccountName",
          type: "input",
        },
        {
          label: "accountId",
          prop: "accountId",
          type: "input",
        },
        {
          label: "agreementNo",
          prop: "agreementNo",
          type: "input",
        },
        {
          label: "登录账号",
          prop: "loginName",
          type: "input",
        },
      ],
    };
  },
  created() {
    // this.setStatus();
    this.getUserList();
  },
  methods: {
    async getStatus(id) {
      try {
        if (!this.statusMap[id]) {
          this.statusMap[id] = "未知";
          let res = await api.common.commonGetDiy({
            url: "/ctrip/old/spring/getTicketingSwitchOrigin",
            baseURL: "/api",
            raw: true,
            params: {
              agentCode: id,
            },
          });
          console.log("status", res);
          if (res.data.success&&res.data.success!=='false') {
            if (res.data.data&&res.data.data!=='false') {
              this.statusMap[id] = "开启";
            } else {
              this.statusMap[id] = "关闭";
            }
          } else {
            this.statusMap[id] = "未登录";
          }
          this.dataLength++;

          if (this.dataLength == this.appListData.length) {
            this.searchQuery.randNum++;
          }
        }
      } catch (error) {
        this.$message.error("未登录携程平台！");
        console.log(error);
      }
    },
    getAppListData(e) {
      this.appListData = e;
    },
    //并发获取状态

    async batchGetStatus(arr) {
      let PromiseArr = [];
      for (let index = 0; index < arr.length; index++) {
        PromiseArr.push(this.getStatus(arr[index].id));
      }

      try {
        let resLast = await Promise.allSettled(PromiseArr);
        console.log(resLast);
      } catch (error) {
        console.log("错误", error);
      }
    },

    async handleTime() {
      let res = await api.common.commonPost({
        apiName: "/ctripApiParam/ticketingSwitchTask",
        openTime: this.openTime,
        closeTime: this.closeTime,
        twoOpenTime: this.twoOpenTime,
        twoCloseTime: this.twoCloseTime,
        ids: this.ids,
      });
      if (res.code == 200) {
        this.$message.success("修改成功！");
        this.openDialogVisible = false;
        this.searchQuery.randNum++;
      }
    },

    setSwitchTime() {
      this.ids = [];
      let arr = this.$refs.applist.selecTionArr;
      if (arr.length == 0) {
        this.$message.error("请先选择数据");
      } else {
        for (let index = 0; index < arr.length; index++) {
          this.ids.push(arr[index].id);
        }
        this.openDialogVisible = true;
      }
    },
    async handleSwitch(str) {
      this.ids = [];
      let arr = this.$refs.applist.selecTionArr;
      if (arr.length == 0) {
        this.$message.error("请先选择数据");
      } else {
        for (let index = 0; index < arr.length; index++) {
          this.ids.push(arr[index].id);
        }

        let res = await api.common.commonPost({
          apiName: "/ctripApiParam/ticketingSwitchBatch",
          actionType: str,
          ids: this.ids,
        });
        if (res.code == 200) {
          this.$message.success("操作成功！");
        }
      }
    },
    //同步默认商品到商户

    handleSelectionChange(e) {
      this.form.userIdList = [];
      e.forEach((element) => {
        this.form.userIdList.push(element.id);
      });
    },

    async handleSave() {
      if (this.isBatch) {
        let obj = {
          userIdList: this.form.userIdList,
          idList: this.ids,
          apiName: "/ctripApiParam/batchSetUset",
        };
        let res = await api.common.commonPost(obj);
        if (res.code == 200) {
          this.$message.success("批量配置成功！");
          this.isBatch = false;
          this.dialogVisible = false;
        }
      } else {
        let res = await api.common.commonPost(this.form);
        if (res.code == 200) {
          this.$message.success("配置用户成功！");
          this.dialogVisible = false;
        }
      }
    },

    async batchSetUser() {
      this.ids = [];
      let arr = this.$refs.applist.selecTionArr;
      if (arr.length == 0) {
        this.$message.error("请先选择数据");
      } else {
        if (this.$refs.multipleTable) {
          this.$refs.multipleTable.clearSelection();
        }
        for (let index = 0; index < arr.length; index++) {
          this.ids.push(arr[index].id);
        }
        this.isBatch = true;
        this.dialogVisible = true;
      }
    },

    async handleUsers(id) {
      let res = await api.common.commonGet({
        apiName: "/ctripApiParam/detail",
        id: id,
      });
      if (res.code == 200) {
        this.form.id = id;
        this.form.agentCode = id;
        this.form.userIdList = res.data.userIdList;

        let arr = [];
        res.data.userIdList.forEach((item) => {
          let uArr = this.userList.filter((v) => v.id == item);

          if (uArr.length > 0) {
            arr.push(uArr[0]);
          }
        });

        this.dialogVisible = true;

        //table回显
        this.$nextTick(function () {
          this.$refs.multipleTable.clearSelection();
          arr.forEach((row) => {
            console.log(row);
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        });
      }
    },

    handlePayUser() {},

    async getUserList() {
      let res = await api.common.commonGet({ apiName: "/user", pageSize: 100 });
      if (res.code == 200) {
        this.userList = res.data.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.item {
  display: inline-block;
  width: 46%;
  margin: 0 2%;
}
.item {
  margin-bottom: 20px;
}
.item span {
  font-size: 16px;
}
.item {
  /deep/ .el-date-editor.el-input {
    width: 150px;
  }
}
.centerDiv {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
